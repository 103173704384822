import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';

// Components
import { Drawer } from '../navigation/drawer';
import { AppBar } from '../navigation/appBar';

export const ScreenLayout = ({ children, title }) => {

    const [open, setOpen] = useState(false);

    const toggleDrawer = useCallback(() => {
        setOpen(!open);
    }, [setOpen, open]);

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <AppBar open={open} toggleDrawer={toggleDrawer} title={title} />
            <Drawer open={open} toggleDrawer={toggleDrawer} />
            {children}
        </Box>
    );
};
