import React from 'react';
import { Box, Grid } from '@mui/material';

// Components
import { StoriesByBusiness, StoriesByCity, StoriesCount } from './components';

export const DashboardScreen = () => {
    return (
        <Box mt={10} px={2} width={'100%'}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} xl={4}>
                    <StoriesByBusiness />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <StoriesByCity />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <StoriesCount />
                </Grid>
            </Grid>
        </Box>
    );
};
