import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { Dashboard } from "@mui/icons-material";

export const Navigation = () => {
  const navigate = useNavigate();

  const onNavigate = useCallback(
    (page) => {
      navigate(`/${page}`);
    },
    [navigate]
  );

  return (
    <ListItemButton onClick={() => onNavigate("dashboard")}>
      <ListItemIcon>
        <Dashboard />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
  );
};
