import { Box, Grid } from "@mui/material";
import React, { useCallback, useState } from "react";

// Queries
import { getStories } from "../../../../db_queries/stories";

// Components
import { CounterList } from "../../../../components/counterList";
import { SearchInput } from "../../../../components/searchInput";

export const StoriesByCity = () => {
  const { getStoriesByCity } = getStories();

  const [storiesByCity, setStoriesByCity] = useState(null);
  const [search, setSearch] = useState("");
  const [loadingStories, setLoadingStories] = useState(false);

  const getStoriesByCityFun = useCallback(
    (search) => {
      setLoadingStories(true);
      getStoriesByCity(search)
        .then((res) => setStoriesByCity(res))
        .catch((error) => {
          setStoriesByCity(null);
          console.error(error.message);
        })
        .finally(() => {
          setLoadingStories(false);
        });
    },
    [getStoriesByCity]
  );

  const onClickHandler = useCallback(
    (value) => {
      setSearch(value);
      if (value.length) {
        getStoriesByCityFun(value);
      } else {
        setStoriesByCity(null);
      }
    },
    [getStoriesByCityFun]
  );

  return (
    <>
      <Grid item xs={12}>
        <SearchInput
          onClick={onClickHandler}
          placeholder="Search stories by city"
        />
      </Grid>
      {loadingStories ? (
        <Box mt={2}>Loading...</Box>
      ) : storiesByCity ? (
        <Grid item xs={12} mt={2}>
          <CounterList counts={storiesByCity} />
        </Grid>
      ) : search.length ? (
        <Box mt={2}>No results</Box>
      ) : null}
    </>
  );
};
