import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

// Hooks
import { AuthProvider } from './hooks/useAuth';

// Components
import { NotFound } from './components/pageNotFound';

// Pages
import { Login } from './screens/login';
import { DashboardManager, DashboardScreen } from "./screens/dashboard";

export const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/dashboard" element={<DashboardManager />} >
            <Route index element={<DashboardScreen />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
