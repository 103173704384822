import React, { useCallback, useState } from 'react';
import { FormControl, OutlinedInput } from '@mui/material';
import { Search } from '@mui/icons-material';

export const SearchInput = ({ placeholder, onClick }) => {
    const [value, setValue] = useState('');

    const onChangeHandler = useCallback((e) => {
        setValue(e.target.value);
    }, [setValue]);

    const onKeyDownHandler = useCallback((e) => {
        if (e.key === 'Enter') {
            onClick(value);
        }
    }, [value, onClick]);

    return (
        <FormControl fullWidth variant="standard">
            <OutlinedInput
                value={value}
                placeholder={placeholder}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandler}
                endAdornment={<Search />}
            />
        </FormControl>
    );
};
