import { Box, Typography } from '@mui/material';
import React from 'react';

import './style.scss';

export const NotFound = () => {
    return (
        <Box className="pageWrapper">
            <Typography component="h1">
                404
            </Typography>
            <Typography component="h3">
                Page not found
            </Typography>
        </Box>
    );
};
