import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Typography,
} from "@mui/material";

// Hooks
import { useAuth } from "../../hooks/useAuth";

// Style
import "./style.scss";

export const Login = () => {
  const { login } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [globalError, setGlobalError] = useState("");

  const emailRegex = /[\w\d\\.-]+@[\w\d\\.-]+\.[\w\d\\.-]+/g;

  const onButtonClick = () => {
    // Set initial error values to empty
    setEmailError("");
    setPasswordError("");
    setGlobalError("");

    // Check if the user has entered both fields correctly
    if (email === "") {
      setEmailError("Please enter your email");
      return;
    }

    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
      return;
    }

    if (password === "") {
      setPasswordError("Please enter a password");
      return;
    }

    if (password.length < 7) {
      setPasswordError("The password must be 8 characters or longer");
      return;
    }

    login(email, password).catch((e) => {
        if (e === 'auth/wrong-password') {
            setPasswordError("Invalid password");
        } else if (e === 'auth/user-not-found') {
            setEmailError("Email not found");
        } else if (e === 'auth/too-many-requests') {
            setGlobalError('Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.');
        }
    });
  };

  return (
    <Box className="formWrapper">
      <Box className="mainContainer">
        <Typography className="titleContainer">Login</Typography>
        {globalError.length ? (
            <Box mb={3.5} color='red'>{globalError}</Box>
        ) : null}
        <FormControl className="inputContainer">
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
            className="inputBox"
          />
          <Box className="errorLabel">{emailError}</Box>
        </FormControl>
        <FormControl className="inputContainer" sx={{ mt: 2 }}>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
            className="inputBox"
          />
          <Box className="errorLabel">{passwordError}</Box>
        </FormControl>
        <Button onClick={onButtonClick} className="styledButton">
          Log in
        </Button>
      </Box>
    </Box>
  );
};
