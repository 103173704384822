import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";

// Queries
import { getStories } from "../../../../db_queries/stories";
import { CounterList } from "../../../../components/counterList";

export const StoriesCount = () => {
  const { countStoriesByCategory } = getStories();

  const [counts, setCounts] = useState({});

  const countStoriesByCategoryFun = useCallback(() => {
    countStoriesByCategory()
      .then((res) => {
        setCounts(res);
      })
      .catch((error) => console.error(error.message));
  }, [countStoriesByCategory]);

  return (
    <Box border="1px solid silver" borderRadius={1} padding={1}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography>Count magnets by category</Typography>
        <Button variant="outlined" onClick={countStoriesByCategoryFun}>
          Count
        </Button>
      </Box>
      <Box mt={2}>
        <CounterList counts={counts} />
      </Box>
    </Box>
  );
};
