import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";

import { db } from "./db";

export const getStories = () => {
  return {

    getStoriesByBusiness: (business) =>
      new Promise(async (resolve, reject) => {
        const storiesRef = collection(db, "Stories");
        const qBusiness = where("referenceToBusiness", "==", business);

        const qBirthday = query(
          storiesRef,
          qBusiness,
          where("magnetCategory", "==", "birthday")
        );
        const birthday = {
          birthday: await (await getCountFromServer(qBirthday)).data().count,
        };

        const qTrip = query(
          storiesRef,
          qBusiness,
          where("magnetCategory", "==", "trip")
        );
        const trip = {
          trip: await (await getCountFromServer(qTrip)).data().count,
        };

        const qOther = query(
          storiesRef,
          qBusiness,
          where("magnetCategory", "not-in", ["birthday", "trip"])
        );

        const other = {
          other: await (await getCountFromServer(qOther)).data().count,
        };

        Promise.all([birthday, trip, other])
          .then((res) => {
            let data = {};
            let total = 0;
            res.forEach((s) => {
              const keys = Object.keys(s);
              total = total + s[keys[0]];
              data = { ...data, ...s };
            });
            data.total = total;
            resolve(data);
          })
          .catch((e) => reject(e));
      }),

    getStoriesByCity: (search) =>
      new Promise(async (resolve, reject) => {
        const firstLetterUppercase =
          search.charAt(0).toUpperCase() + search.slice(1);
        const storiesRef = collection(db, "Stories");
        const qFilter1 = where("magnet.magnetImage.location.city", ">=", firstLetterUppercase);
        const qFilter2 = where("magnet.magnetImage.location.city", "<=", firstLetterUppercase + "\uf8ff");

        const qBirthday = query(
          storiesRef,
          qFilter1,
          qFilter2,
          where("magnetCategory", "==", "birthday")
        );

        const birthday = {
          birthday: await (await getCountFromServer(qBirthday)).data().count,
        };

        const qTrip = query(
          storiesRef,
          qFilter1,
          qFilter2,
          where("magnetCategory", "==", "trip")
        );

        const trip = {
          trip: await (await getCountFromServer(qTrip)).data().count,
        };

        const qOther = query(
          storiesRef,
          qFilter1,
          qFilter2,
          where("magnetCategory", "not-in", ["birthday", "trip"])
        );

        const other = {
          other: await (await getCountFromServer(qOther)).data().count,
        };

        Promise.all([birthday, trip, other])
          .then((res) => {
            let data = {};
            let total = 0;
            res.forEach((s) => {
              const keys = Object.keys(s);
              total = total + s[keys[0]];
              data = { ...data, ...s };
            });
            data.total = total;
            resolve(data);
          })
          .catch((e) => reject(e));
      }),

    countStoriesByCategory: () =>
      new Promise(async (resolve, reject) => {
        const storiesRef = collection(db, "Stories");

        const qBirthday = query(
          storiesRef,
          where("magnetCategory", "==", "birthday")
        );

        const birthday = {
          birthday: await (await getCountFromServer(qBirthday)).data().count,
        };

        const qTrip = query(
          storiesRef,
          where("magnetCategory", "==", "trip")
        );

        const trip = {
          trip: await (await getCountFromServer(qTrip)).data().count,
        };

        const qOther = query(
          storiesRef,
          where("magnetCategory", "not-in", ["birthday", "trip"])
        );

        const other = {
          other: await (await getCountFromServer(qOther)).data().count,
        };

        Promise.all([birthday, trip, other])
          .then((res) => {
            let data = {};
            let total = 0;
            res.forEach((s) => {
              const keys = Object.keys(s);
              total = total + s[keys[0]];
              data = { ...data, ...s };
            });
            data.total = total;
            resolve(data);
          })
          .catch((e) => reject(e));
      }),
  };
};
