import { Box, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

// Queries
import { getBusinesses } from "../../../../db_queries/business";
import { getStories } from "../../../../db_queries/stories";

// Hooks
import { useAuth } from "../../../../hooks/useAuth";

// Components
import { SearchableDropdown } from "../../../../components/searchableDropdown";
import { CounterList } from "../../../../components/counterList";

export const StoriesByBusiness = () => {
  const { getStoriesByBusiness } = getStories();
  const { getAllBusinesses } = getBusinesses();
  const { user } = useAuth();

  const [businesses, setBusinesses] = useState([]);
  const [storiesByBusiness, setStoriesyBusiness] = useState(null);
  const [loadingStory, setLoadingStory] = useState(false);
  const [selected, setSelected] = useState(null);

  const getAllBusinessesFun = useCallback(() => {
    getAllBusinesses()
      .then((res) => setBusinesses(res))
      .catch((error) => {
        setBusinesses([]);
        console.error(error.message);
      });
  }, [getAllBusinesses]);

  const getStoriesByBusinessFun = useCallback(
    (business) => {
      setLoadingStory(true);
      getStoriesByBusiness(business)
        .then((res) => setStoriesyBusiness(res))
        .catch((error) => {
          setStoriesyBusiness(null);
          console.error(error.message);
        })
        .finally(() => {
          setLoadingStory(false);
        });
    },
    [getStoriesByBusiness]
  );

  const onSelectBusiness = useCallback(
    (value) => {
      if (value?.id) {
        setSelected(value);
        getStoriesByBusinessFun(value.id);
      } else {
        setSelected(null);
        setStoriesyBusiness(null);
      }
    },
    [getStoriesByBusinessFun]
  );

  useEffect(() => {
    if (user && !businesses?.length) {
      getAllBusinessesFun();
    }
  }, [getAllBusinessesFun, user, businesses]);

  return (
    <>
      <Grid item xs={12}>
        <SearchableDropdown
          options={businesses}
          placeholder="Select business"
          onChangeHandler={onSelectBusiness}
          clearable={true}
        />
      </Grid>
      {loadingStory ? (
        <Box mt={2}>Loading...</Box>
      ) : selected && !storiesByBusiness ? (
        <Box mt={2}>No stories</Box>
      ) : storiesByBusiness ? (
        <Grid item xs={12} mt={2}>
          <CounterList counts={storiesByBusiness} />
        </Grid>
      ) : null}
    </>
  );
};
