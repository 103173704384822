import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAiYZUnNwoyZlOO1HgAXz02pHw5wqVKysw",
    authDomain: "gulli-39333.firebaseapp.com",
    projectId: "gulli-39333",
    storageBucket: "gulli-39333.appspot.com",
    messagingSenderId: "157785372572",
    appId: "1:157785372572:web:3c6ef37d47139d3ffc7a59",
    measurementId: "G-GLVPLL2NDD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

 export {db, auth};