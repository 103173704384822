import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

import { auth } from "../db_queries/db";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useLocalStorage("user", null);

  useEffect(() => {
    if (!user) {
      localStorage.removeItem("user");
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  // call this function when you want to authenticate the user
  const login = useCallback(
    (email, password) => {
      return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(auth, email, password)
          .then((res) => {
            if (res?.user) {
              setUser({
                id: res.user.uid,
                name: res.user.displayName || res.user.email,
              });
              navigate("/dashboard");
            }
          })
          .catch((error) => {
            console.error(error.message);
            reject(error.code);
          });
      });
    },
    [setUser, navigate]
  );

  // call this function to sign out logged in user
  const logout = useCallback(() => {
    signOut(auth)
      .then(() => {
        setUser(null);
        localStorage.removeItem("user");
        navigate("/", { replace: true });
      })
      .catch((error) => console.error(error.message));
  }, [setUser, navigate]);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user, login, logout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
