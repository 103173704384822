import React from 'react';
import { Box, IconButton, Toolbar, Typography } from '@mui/material';
import { Logout, Menu } from '@mui/icons-material';

// Hooks
import { useAuth } from '../../../hooks/useAuth';

// Style
import { StyledAppBar } from './style';

export const AppBar = ({ open, toggleDrawer, title }) => {

    const { user, logout } = useAuth();

    return (
        <StyledAppBar position="absolute" open={open}>
            <Toolbar
                sx={{ pr: '24px' }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                    }}
                >
                    <Menu />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    {title}
                </Typography>
                <Box display={'flex'} alignItems={'center'} columnGap={1}>
                    <Typography>{user?.name}</Typography>
                    <IconButton color="inherit" onClick={logout}>
                        <Logout />
                    </IconButton>
                </Box>
            </Toolbar>
        </StyledAppBar>
    );
};
