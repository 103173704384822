import React from "react";
import { Chip, Divider, List, ListItem, ListItemText } from "@mui/material";

import "./style.scss";

export const CounterList = ({ counts }) => {
  const countsLenght = counts ? Object.keys(counts).length : null;

  if (!countsLenght) {
    return null;
  }

  return (
    <List className="styledList">
      {Object.keys(counts).map((c, index) => {
        const isTotal = c === "total";
        return (
          <React.Fragment key={`${c}-${index}`}>
            <ListItem
              disablePadding
              secondaryAction={
                <Chip
                  color={isTotal ? "default" : "info"}
                  label={counts[c]}
                  sx={{ fontSize: 16 }}
                  className={isTotal ? "total" : ""}
                />
              }
            >
              <ListItemText
                primary={c.charAt(0).toUpperCase() + c.slice(1)}
                sx={{ pl: 1.5 }}
                className={isTotal ? "total" : ""}
              />
            </ListItem>
            {Object.keys(counts).length > index + 1 ? (
              <Divider component="li" sx={{ margin: "8px 0" }} />
            ) : null}
          </React.Fragment>
        );
      })}
    </List>
  );
};
