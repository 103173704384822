import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useMemo, useState } from "react";

import "./style.scss";

export const SearchableDropdown = ({
    options,
    clearable = false,
    placeholder,
    onChangeHandler,
}) => {
    const [open, setOpen] = useState(false);

    const loading = useMemo(() => open && options.length === 0, [open, options]);

    return (
        <Autocomplete
            sx={{ minWidth: 350 }}
            open={open}
            disableClearable={!clearable}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            onChange={(e, value) => onChangeHandler(value)}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        placeholder: placeholder || 'Select option'
                    }}
                />
            )}
            renderOption={(props, option) => {
                const { key, className, ...optionProps } = props;
                return (
                    <Box
                        key={key}
                        component="li"
                        className={`${className} option`}
                        {...optionProps}
                    >
                        <Box className="styledOption">
                            <Typography>{option.name}</Typography>
                            {option.image && (
                                <img
                                    loading="lazy"
                                    src={option.image}
                                    alt=""
                                />
                            )}
                        </Box>
                    </Box>
                );
            }}
        />
    );
};
