import React from 'react';
import { Outlet } from 'react-router-dom';

// Component
import { ScreenLayout } from '../../components/screenLayout';

export const DashboardManager = () => {
    return (
        <ScreenLayout title='Dashboard'>
            <Outlet />
        </ScreenLayout>
    );
};
