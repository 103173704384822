import { collection, getDocs } from "firebase/firestore";

import { db } from "./db";

export const getBusinesses = () => {
  return {
    getAllBusinesses: () =>
      new Promise((resolve, reject) => {
        const ref = collection(db, "Businesses");
        getDocs(ref)
          .then((res) => {
            let data = [];
            if (!res.empty) {
              res.forEach((b) => {
                data.push({
                  id: b.id,
                  name: b.id,
                  image: b.data()?.businessImageUrl,
                });
              });
            }
            resolve(data);
          })
          .catch((e) => reject(e.message));
      }),
  };
};
