import React from 'react';
import { Divider, IconButton, List, Toolbar, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

// Style
import { StyledDrawer } from './style';
import { Navigation } from '../navigation';

export const Drawer = ({ open, toggleDrawer }) => {
    return (
        <StyledDrawer variant="permanent" open={open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: [1],
                }}
            >
                <Typography component="h2" variant="h6" color="primary" pl={2} fontWeight={'bold'}>Gulli</Typography>
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeft />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
                <Navigation />
            </List>
        </StyledDrawer>
    );
};
